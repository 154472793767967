// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "CognitoAppApiDB-test",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:1bce103e-b4d6-4aee-a3fa-fab02bb55361",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_zjJCrdRYk",
    "aws_user_pools_web_client_id": "7v71q4f2tvjtdsanducogoa4od",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "CognitoAppApi",
            "endpoint": "https://0rhf2kmcue.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
