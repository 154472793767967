import React from 'react'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
// import './Auth.css'
// import { InputGroup, Input } from 'reactstrap';

import { Auth } from 'aws-amplify'
import { useHistory, useLocation } from 'react-router-dom';

const AuthComp = () => {
    const [data, setData] = React.useState({username: '', password: ''});
    const [form, setForm] = React.useState({dirty: false, submitting: false});
    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" }}

    const signIn = () => {
        Auth.signIn(
            data.username,
            data.password,
        ).then(user => {
            console.log(user)
            history.replace(from)
        })
        .catch(err => console.log(err));
    }

    const onFormChange = event => {
        setForm({ ...form, dirty: true });
        setData({ ...data, [event.target.name]: event.target.value });
    };

    const onFormSubmit = async event => {
        event.preventDefault();

        setForm({ ...form, submitting: true });
        signIn(data);

        setData({ username: '', password: ''})
        setForm({ dirty: false, submitting: false });

    }

    const redirectToRegister = () => {
        return (
            history.push('/register')
        )
    }

    return (
        <div className="auth-container">
        <div className="auth-box">
        <Form
            onChange={onFormChange}
            onSubmit={onFormSubmit}
        >
            <FormGroup className="auth-username">
                <Label for="username">UserName</Label>
                <Input type="username" name="username" onChange={onFormChange} placeholder="Username..." />
            </FormGroup>
            <FormGroup className="auth-password">
                <Label for="password">Password</Label>
                <Input type="password" name="password" onChange={onFormChange} placeholder="Password..." />
            </FormGroup>
            <FormGroup className="auth-action-container" type="submit">
                <Button 
                    className="auth-login" 
                    onClick={signIn}
                >
                    Login
                </Button>
                <Button onClick={redirectToRegister}>Register!</Button>
            </FormGroup>
        </Form>
    </div>
    </div>           
        )
}

export default AuthComp