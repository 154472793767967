import React from 'react';
import { FormGroup, Label, Form, Input, Button } from 'reactstrap';
import {useHistory, useLocation } from 'react-router-dom'

import { Auth } from 'aws-amplify';

const Registration = () => {
    const [data, setData] = React.useState({username: '', password: '', email: ''})
    ;
    const [form, setForm] = React.useState({dirty: false, submitting: false});
    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" }}

    async function register ({username, password, email}) {
        Auth.signUp({
            username, 
            password, 
            attributes: { email }
              // validationData: [],  // optional
        })
        .then(data => {
            console.log(`Signup Success: ${data}`)
            history.replace(from)
        })
        .catch(err => console.log(err));
    }

    const onFormChange = event => {
        setForm({ ...form, dirty: true });
        setData({ ...data, [event.target.name]: event.target.value });
    };

    const onFormSubmit = async event => {
        event.preventDefault();

        setForm({ ...form, submitting: true });
        register(data);

        setData({ username: '', password: ''})
        setForm({ dirty: false, submitting: false });

    }

    return(
        <Form> 
            <FormGroup>
                <Label>Username</Label>
                <Input type="username" name="username" onChange={onFormChange} />
            </FormGroup>
            <FormGroup>
                <Label>Email</Label>
                <Input type="email" name="email" onChange={onFormChange} />
            </FormGroup>
            <FormGroup>
                <Label>Password</Label>
                <Input type="password" name="password" onChange={onFormChange} />
            </FormGroup>
            <Button onClick={onFormSubmit}>Register!</Button>
        </Form>
    )
}

export default Registration;