import React from 'react';
import {
  Switch,
  Route,
  Link,
  Redirect
} from 'react-router-dom';
import { Auth } from 'aws-amplify';
// import awsconfig from './aws-exports';
// import logo from './logo.svg';
import './App.css';
import AuthComp  from './auth/Auth'
import Registration from './auth/Registration'
import Users from './users/Users'

function App(props) {
  // useEffect(() => { 
  //   async function getData() {
  //     let apiName = 'CognitoAppApi';
  //     let path = '/users'
  //     let myInit = {
  //       // headers: {}, // OPTIONAL
  //       // response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  //       // queryStringParameters: {  // OPTIONAL
  //       //     name: 'param'
  //     }
  //     return await API.get(apiName, path, myInit).then(response => {

  //     }).catch(error => {
  //       console.log(error.response)
  //     });
  //   };
  // });

  return (
    <div className="App">
      <header className="App-header">
        <div>
          <Link to="/">Home</Link>
        </div>
        <div>
          <Link to="/users">Users</Link>
        </div>
        <div>
          <Link to="/register">Register</Link>
        </div>
        
        <Switch>
          <Route path="/register">
           <Redirect from='/*' to='/register'/>
            <Registration />
          </Route>    
          <PrivateRoute path="/users">
            <Users />
          </PrivateRoute>

          <Route path="/">
            <AuthComp />
          </Route>
        </Switch>
        {/* // <button >Sign In</button> */}
      </header>
    </div>
  );
}

export function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location })  =>
        Auth.isAuthenticated ? (
          children
        ) : (
          <Redirect to={{
            pathname: "/",
            state: { from: location }
          }}
          status={401}
          />
        )
    }
    /> 
  );
}

export default App;
